/* eslint-disable vue/multi-word-component-names */

import { createApp } from "vue";
import App from "./App.vue";
import PrimeVue from "primevue/config";
import router from "./router";

import Calendar from "primevue/calendar";
import Dropdown from "primevue/dropdown";
import InputText from "primevue/inputtext";

import { firebaseConnector } from "./firebase";

const app = createApp(App);

app.use(router);
app.use(PrimeVue);

app.component("Calendar", Calendar);
app.component("Dropdown", Dropdown);
app.component("InputText", InputText);

app.mount("#app");

firebaseConnector.initialize();
