import { createRouter, createWebHistory, RouteRecordRaw } from "vue-router";

const routes: Array<RouteRecordRaw> = [
  {
    path: "/:pathMatch(.*)*",
    name: "NotFound",
    component: () => import("../views/NotFound.vue"),
  },
  {
    path: "/",
    name: "RegisterView",
    component: () => import("../views/RegisterView.vue"),
  },
  {
    path: "/loading",
    name: "LoadingView",
    component: () => import("../views/LoadingView.vue"),
  },
  {
    path: "/done",
    name: "DoneView",
    component: () => import("../views/DoneView.vue"),
  },
  {
    path: "/error",
    name: "ErrorView",
    component: () => import("../views/ErrorView.vue"),
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

export default router;
