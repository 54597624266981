import firebase from "firebase/compat/app";
import "firebase/compat/firestore";
import "firebase/compat/functions";

import { Candidate } from "@/objects/Candidate";

export class FirebaseConnector {
  private firebaseConfig = {
    apiKey: "AIzaSyCMf3V3DidTVq4IBDb4jskga5eMHH0Kyck",
    authDomain: "helvetica-9bac4.firebaseapp.com",
    projectId: "helvetica-9bac4",
    storageBucket: "helvetica-9bac4.appspot.com",
    messagingSenderId: "640325331324",
    appId: "1:640325331324:web:d1ebd0bcf2f0cac2169cf9",
  };

  public app: firebase.app.App | undefined;

  public initialize(): void {
    this.app = firebase.initializeApp(this.firebaseConfig);
    firebase.firestore();
    firebase.functions();
  }

  public async registerCandidate(candidate: Candidate): Promise<boolean> {
    try {
      if (this.app === undefined) throw new Error("Firebase app is not initialized");

      const createCandidate = this.app?.functions("europe-west1").httpsCallable("featuresCandidateCreate");

      await createCandidate(candidate.toFirestore());

      return true;
    } catch (error) {
      console.error(error);
      return false;
    }
  }
}

export const firebaseConnector: FirebaseConnector = new FirebaseConnector();
